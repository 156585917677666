import { defineStore } from 'pinia'
import type {
  Organization,
  newImage,
  OrganizationTypes,
} from "../models"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useUserStore } from './user.store'
import { useNuxtApp } from '#imports'

interface OrganizationState {
  organization: Organization
  types: OrganizationTypes[]
  newImage: null | newImage
  thumbnailId: null | string
}

export const useOrganizationStore = defineStore('organization', {
  state: (): OrganizationState => ({
    organization: {},
    types: [],
    newImage: null,
    thumbnailId: null, // NOTE FOR FUTUR REBASE be careful to implement it in the new store' .ts file
  }),
  actions: {
    setOrganization (organization) {
      this.organization = JSON.parse(JSON.stringify(organization)) as Organization
    },
    fetchTypes (types) {
      this.types = types as OrganizationTypes[]
    },
    setNewImage (image) {
      this.newImage = image as newImage
    },
    resetNewImage () {
      this.newImage = null
    },
    setThumbnail (id) {
      this.thumbnailId = id as string
    },
    async fetchOrganization (slug: string) {
      try {
        const { $httpApi } = useNuxtApp()
        const response: AxiosResponse = await ($httpApi as AxiosInstance).get(
          `/organizations/${slug}/full`
        )
        const data: Organization = response.data
        this.setOrganization(data)
        return data
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
    },
    async fetchOrganizationTypes () {
      try {
        const { $httpApi } = useNuxtApp()
        const response: AxiosResponse = await ($httpApi as AxiosInstance).get(
          "/values/organization/categories"
        )
        const data: OrganizationTypes = response.data
        this.fetchTypes(data)
        return data
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
    },
    async saveOrganizationData ({ slug, payload }: { slug: string; payload: Record<string, unknown> }) {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).put(`/organizations/${slug}`, payload)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
      await this.fetchOrganization(slug)
    },
    async updateBanner ({ slug, file }: { slug: string; file: File }) {
      const formData = new FormData()
      formData.append("file", file)
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).post(`/organizations/${slug}/banner`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      await this.fetchOrganization(slug)

      const userStore = useUserStore()
      await userStore.setUser(null)
    },
    async deleteBanner (slug) {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).delete(`/organizations/${slug}/banner`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      await this.fetchOrganization(slug)

      const userStore = useUserStore()
      await userStore.setUser(null)
    },
    async updateLogo ({ slug, file }) {
      const formData = new FormData()
      formData.append("file", file)
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).post(`/organizations/${slug}/logo`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      await this.fetchOrganization(slug)

      const userStore = useUserStore()
      await userStore.setUser(null)
    },
    async deleteLogo (slug: string) {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).delete(`/organizations/${slug}/logo`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      await this.fetchOrganization(slug)

      const userStore = useUserStore()
      await userStore.setUser(null)
    },
    async addImage ({ slug, file, caption }: { slug: string; file: { image: File }; caption: string }) {
      const formData = new FormData()

      formData.append("file", new Blob([file.image], { type: file.image.type }), file.image.name)
      formData.append("description", caption)

      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).post(`/organizations/${slug}/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      this.resetNewImage()
      await this.fetchOrganization(slug)
    },
    async deleteImage ({ slug, id }: { slug: string; id: string }) {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).delete(`/organizations/${slug}/image/${id}`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
      // TODO see where deleteVideo should be used and re-add tracking
      /* await this.app.segment.track('Organization Page Updated', {
        organization_slug: slug,
        update_type: ['Retrait Image']
      }) */

      await this.fetchOrganization(slug)
    },
    async addVideo ({ slug, url }: { slug: string; url: string }): Promise<any> {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).post(`/organizations/${slug}/video`, { url })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }

      await this.fetchOrganization(slug)
    },
    async deleteVideo ({ slug, id }: { slug: string; id: string }) {
      try {
        const { $httpApi } = useNuxtApp()
        await ($httpApi as AxiosInstance).delete(`/organizations/${slug}/video/${id}`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
      }
      // TODO see where deleteVideo should be used and re-add tracking
      /* await this.app.segment.track('Organization Page Updated', {
        organization_slug: slug,
        update_type: ['Retrait Vidéo']
      }) */

      await this.fetchOrganization(slug)
    }
  }
})
